import React from 'react'
import { Container } from '../PageContainer'
import './hero.scss'
function Hero({ pageName, title, subTitle, heroImg }) {
  return (
    <div className="bgWhite">
      <div className="blackyBg"></div>
      <Container className="Container" id="hero-container">
        <div className="heroText">
          <div className="heroPage">
            <h6>{pageName}</h6>
          </div>
          <div className="heroTitle">
            <h2 style={{ marginBottom: 25 }}>{title}</h2>
            <h5>{subTitle}</h5>
          </div>
        </div>
      </Container>
      <div className="imgContainer">
        <img className="heroBackgroundImage" src={heroImg} alt="HeroImg" />
      </div>
      <div
        style={{
          height: 75,
          overflow: 'hidden',
          backgroundColor: 'rgba(255,255,255,0)',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          zIndex: '1',
        }}
      >
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: '100%', width: '100%', position: 'relative' }}
        >
          <path
            d="M0.00,49.98 C149.99,150.00 353.83,62.66 501.12,22.20 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: 'none', fill: '#fff' }}
          ></path>
        </svg>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            bottom: '10%',
            left: 0,
          }}
        >
          <path
            d="M0.00,49.98 C149.99,150.00 353.83,62.66 501.12,22.20 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: 'none', fill: 'rgba(255,255,255,0.75)' }}
          ></path>
        </svg>
      </div>
    </div>
  )
}

export default Hero
