import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Layout from 'components/Layout'
import AppStateProvider from '../state'
import Hero from '../components/Hero'
import heroImg from '../assets/images/heroImage.svg'

import { Container } from 'components/PageContainer'
import './styles/workwithus.scss'
import { ContactSupportOutlined } from '@material-ui/icons'

const GATEWAY_URL = 'https://api.swiftdoc.com/api/contact'
const required = 'This field is required'

function RootIndex() {
  const [submitted, setSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm()

  const onSubmit = (data) => {
    console.log(data)
    try {
      fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      setSubmitted(true)
      reset()
    } catch (error) {
      //setError('test', { type: 'type', message: 'issue' }, { shouldFocus: true })
      console.log(error)
    }
  }
  const showSubmitError = (msg) => <p className="msg-error">{msg}</p>

  const showThankYou = (
    <div className="msg-confirm">
      <p>
        Thank you for getting in touch! We appreciate you contacting SwiftDoc.
        One of our colleagues will get back in touch with you soon! 
      </p>
      <p>Have a great day!</p>
      <p>
        <button class="submitBtn" onClick={() => setSubmitted(false)}>
          Send another message?
        </button>
      </p>
    </div>
  )
  const showForm = (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <div style={{width: '100%', marginBottom: '30px' }}>
        <input
          type="text"
          name="name"
          placeholder="Full Name (required)"
          {...register('name', { required: required })}
          disabled={isSubmitting}
          style={{margin: 0}}
        />
        {errors.name && <div className="msg-error">{errors.name.message}</div>}
      </div>

      <div style={{width: '100%', marginBottom: '30px' }}>
        <input
          id="email"
          type="text"
          name="email"
          placeholder="your@email.address (required)"
          {...register('email', {
            required: required,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Entered value does not match email format',
            },
          })}
          disabled={isSubmitting}
          style={{margin: 0}}
        />
        {errors.email && <div className="msg-error">{errors.email.message}</div>}
      </div>

      <div style={{width: '100%', marginBottom: '30px' }}>
        <input
          type="text"
          name="phone"
          placeholder="Phone (required)"
          {...register('phone', { required: required })}
          disabled={isSubmitting}
          style={{margin: 0}}
        />
        {errors.phone && <div className="msg-error">{errors.phone.message}</div>}
      </div>

      <div style={{width: '100%', marginBottom: '30px' }}>
        <input
          type="text"
          name="subject"
          placeholder="Subject (required)"
          {...register('subject', { required: required })}
          disabled={isSubmitting}
          style={{margin: 0}}
        />
        {errors.sujbect && <div className="msg-error">{errors.subject.message}</div>}
      </div>

      <div style={{width: '100%', marginBottom: '30px' }}>
        <textarea
          {...register('question', { required: required })}
          id="question"
          name="question"
          placeholder="Your message"
          disabled={isSubmitting}
          style={{margin: 0}}
        />
        {errors.question && (
          <div className="msg-error">{errors.question.message}</div>
        )}
      </div>

      <button class="submitBtn" type="submit" disabled={isSubmitting}>
        SEND
      </button>
    </form>
  )
  return (
    <AppStateProvider>
      <Layout title="Contact Us | SwiftDoc">
        <div className="margin-container">
          <Hero
            heroImg={heroImg}
            title="Send us a message"
            subTitle="Have a question about our Online Doctor Consultations? Use the form below to send a message to SwiftDoc."
            pageName="CONTACT"
          />
          <div className="form-container">
            <Container>
              <div className="container-wrapper">
                <div className="form-fields-container">
                  <p>Contact Us</p>
                  {errors?.submit && showSubmitError(errors.submit.message)}
                  {submitted ? showThankYou : showForm}
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    </AppStateProvider>
  )
}

export default RootIndex
